import { ThemeOptions } from "@mui/material/styles";

const themeOption: ThemeOptions = {
  palette: {
    primary: {
      contrastText: "#FFF",
      main: "#095C88",
    },
    secondary: {
      contrastText: "#FFF",
      main: "#A436BE",
    },
    info: {
      contrastText: "#FFF",
      main: "#1FA7F1",
    },
    success: {
      contrastText: "#FFF",
      main: "#26A65B",
    },
    error: {
      contrastText: "#FFF",
      main: "#ff9191",
    },
    warning: {
      contrastText: "#000",
      main: "#FDC23A",
    },
  },
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
    fontSize: 14,
    fontWeightBold: 600,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          boxShadow: "none",
          ":hover": {
            boxShadow: "none",
          },
        },
      },
    },
  },
};

export default themeOption;
