import { LinearProgress } from '@mui/material';
import React, { Suspense, lazy } from 'react';

import { useRoutes } from 'react-router-dom';

const Loadable =
  (Component: React.LazyExoticComponent<() => JSX.Element>): React.FC =>
  (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks

    return (
      <Suspense fallback={<LinearProgress />}>
        <Component {...props} />
      </Suspense>
    );
  };

export default function Router() {
  return useRoutes([
    {
      path: 'scan',
      element: <Scan />,
      children: [
        { path: '', element: <Scan /> },
        { path: ':id', element: <Scan /> },
      ],
    },
    {
      path: '/',
      index: true,
      element: <Home />,
    },
  ]);
}

const Home = Loadable(lazy(() => import('../pages/Home')));
const Scan = Loadable(lazy(() => import('../pages/scan/ScanPage')));
