import React from "react";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import themeOption from "./options";

interface props {
  children: React.ReactNode;
}

const CustomTheme = ({ children }: props) => {
  const themes = createTheme(themeOption);

  return (
    <StyledEngineProvider>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default CustomTheme;
